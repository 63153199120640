import React from 'react';

const TermsAndConditions = () => {
    return (
        <section className="wpo-service-single-section">
            <div className="container flex justify-center">
                <div className="col-lg-12 col-md-12">
                    <div className="wpo-service-single-wrap">
                        <div className="wpo-service-single-content">
                            <div className="container mx-auto my-10 p-5">
                                <div className="flex justify-center">
                                    <h1 className="text-3xl font-bold mb-4">Terms & Conditions</h1>
                                </div>

                                <p className="font-bold mb-4">
                                    IMPORTANT NOTICE
                                </p>

                                <p className="mb-4">
                                    PLEASE READ THE FOLLOWING BEFORE PROCEEDING TO VIEW ANY MATERIAL ON THIS WEBSITE:<br/>
                                    BY CONTINUING TO ACCESS AND USE THIS WEBSITE, YOU ARE AGREEING TO ALL OF THE TERMS OF USE SET OUT BELOW.
                                </p>

                                <h2 className="text-2xl font-semibold mt-6 mb-3">1. Introduction</h2>
                                <p className="mb-4">
                                These Terms and Conditions ("Terms") govern your use of Aelyx (the "Website") operated by Aelyx Techventures. By accessing or using our Website at aelyx.in, you agree to comply with these Terms. If you do not agree with these Terms, please refrain from using our Website.

                                </p>

                                <h2 className="text-2xl font-semibold mt-6 mb-3">2. Services Provided</h2>
                                <p className="mb-4">
                                Aelyx provides information, comparisons, and access to financial products, including insurance, loans, and investment options. All information on the Website is for informational purposes only and does not constitute financial advice.
                                </p>

                                <h2 className="text-2xl font-semibold mt-6 mb-3">3. User Obligations</h2>
                                <p className="mb-4">
                                    <strong>Eligibility:</strong> You must be at least 18 years old to use our Website.
                                </p>
                                <p className="mb-4">
                                    <strong>Accuracy of Information:</strong> You agree to provide accurate, current, and complete information. You are responsible for maintaining the confidentiality of any account or login credentials.
                                </p>
                                <p className="mb-4">
                                    <strong>Prohibited Activities:</strong> Users are prohibited from engaging in activities that compromise the Website’s integrity, such as unauthorized data extraction, use of automated tools, or any form of cyber-attack.
                                </p>

                                <h2 className="text-2xl font-semibold mt-6 mb-3">4. Disclaimer of Liability</h2>
                                <p className="mb-4">
                                    <strong>No Financial Advice:</strong> The information provided by Aelyx is for informational purposes only and does not constitute financial, legal, or professional advice. For specific financial advice, consult a qualified professional.
                                </p>
                                <p className="mb-4">
                                    <strong>Service Accuracy:</strong> Aelyx Techventures strives to ensure the accuracy of information; however, it does not guarantee the completeness, reliability, or suitability of products listed on the Website.
                                </p>

                                <h2 className="text-2xl font-semibold mt-6 mb-3">5. Third-Party Links and Services</h2>
                                <p className="mb-4">
                                    Aelyx may contain links to third-party websites or services not owned or controlled by Aelyx Techventures. We are not responsible for the content or practices of third-party websites. Accessing these links is at your own risk.
                                </p>

                                <h2 className="text-2xl font-semibold mt-6 mb-3">6. Intellectual Property</h2>
                                <p className="mb-4">
                                    All content, logos, trademarks, and intellectual property displayed on this Website are the property of Aelyx Techventures or its licensors. You may not copy, modify, or distribute any part of the Website’s content without prior written permission.
                                </p>

                                <h2 className="text-2xl font-semibold mt-6 mb-3">7. Limitation of Liability</h2>
                                <p className="mb-4">
                                    Aelyx Techventures shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from your use of, or inability to use, Aelyx or its services, even if advised of such damages.
                                </p>

                                <h2 className="text-2xl font-semibold mt-6 mb-3">8. Indemnification</h2>
                                <p className="mb-4">
                                    You agree to indemnify, defend, and hold harmless Aelyx Techventures, its affiliates, directors, employees, and agents from any claims, damages, liabilities, or expenses arising from your use of Aelyx or violation of these Terms.
                                </p>

                                <h2 className="text-2xl font-semibold mt-6 mb-3">9. Privacy Policy</h2>
                                <p className="mb-4">
                                    Our Privacy Policy, available at [Privacy Policy], describes how we handle and protect your personal data. By using Aelyx, you consent to our collection and use of personal data as outlined in the Privacy Policy.
                                </p>

                                <h2 className="text-2xl font-semibold mt-6 mb-3">10. Amendments</h2>
                                <p className="mb-4">
                                    Aelyx Techventures reserves the right to update or modify these Terms at any time without prior notice. Changes are effective immediately upon posting on this page, and continued use of the Website constitutes your acceptance of the revised Terms.
                                </p>

                                <h2 className="text-2xl font-semibold mt-6 mb-3">11. Governing Law</h2>
                                <p className="mb-4">
                                    These Terms are governed by the laws of Mangalore, Karnataka, without regard to its conflict of law provisions. Any disputes arising from these Terms or the use of Aelyx shall be resolved exclusively in the courts of Mangalore, Karnataka.
                                </p>

                                <h2 className="text-2xl font-semibold mt-6 mb-3">12. Contact Information</h2>
                                <p className="mb-4">
                                    For questions or concerns regarding these Terms, please contact us at:
                                    <br />
                                    Email: support@aelyx.in <br />
                                    Phone: +91-9513716226
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TermsAndConditions;
