import React from 'react'
import {Link} from 'react-router-dom'

import prdimg1 from '../../images/icon/document.svg'
import prdimg2 from '../../images/icon/bar-graph.svg'
import prdimg3 from '../../images/icon/clipboard.svg'



const Features = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


     const featres = [
         {
            fIcon:prdimg1,
            title:'Effortless Client Onboarding:',
            des:'Streamlined client onboarding process with secure, digital identity verification and simple risk profiling, enabling clients to get started quickly and confidently.',      
            link:'/service-single',
         },
         {
            fIcon:prdimg2,
            title:'Tailored Service Selection:',
            des:'Empower clients to choose the advisory services that best fit their financial goals, ensuring personalized investment strategies for long-term success.',      
            link:'/service-single',
         },
         {
            fIcon:prdimg3,
            title:'Digital Agreement & Compliance:',
            des:'Ensure seamless digital agreement signing, making the process secure, efficient, and fully compliant with regulatory requirements.',      
            link:'/service-single',
         },
         {
            fIcon:prdimg3,
            title:'Fast & Secure Payments:',
            des:'Facilitate smooth and secure payment transactions with integrated gateways, ensuring hassle-free financial interactions for clients and advisors.',      
            link:'/service-single',
         },
         {
            fIcon:prdimg3,
            title:'Real-Time Order Execution:',
            des:'Offer fast, real-time stock order execution on major exchanges, ensuring clients’ trades are handled efficiently with complete transparency.',      
            link:'/service-single',
         },
         {
            fIcon:prdimg3,
            title:'Enhanced Client-Adviser Relationship:',
            des:'Foster stronger relationships between clients and advisors with personalized portfolio management and ongoing advisory support tailored to clients evolving financial needs.',      
            link:'/service-single',
         },
         {
            fIcon:prdimg3,
            title:'Regulatory Compliance & Data Security:',
            des:'Guarantee compliance with SEBI regulations while maintaining the highest standards of data security and privacy, safeguarding client information at every stage.',      
            link:'/service-single',
         },
         {
            fIcon:prdimg3,
            title:'Informed Decision-Making:',
            des:'Provide clients with insightful, data-driven advice and tools to make informed investment decisions, aligning with their long-term financial objectives.',      
            link:'/service-single',
         },
         
     ]


    return(
        <section id="target" className={`wpo-features-section section-padding  ${props.featuresClass}`}
        // style={{
        //     width: '100%', // Full width of the container
        //     height: '100vh', // Full viewport height (100% of the screen height)
        //     display: 'flex', // Flex container to align children (optional based on content)
        //     flexDirection: 'column', // Align content in a column (optional)
        //     justifyContent: 'center', // Center content vertically (optional)
        //     alignItems: 'center', // Center content horizontally (optional)
        //   padding:1
        //   }}
        >
            <div className="container mt-8">
                <div className="row">
                <div className="wpo-section-title">
                          
                          <h2> Outcome Targeted:</h2>
                      </div>
                    {featres.map((featres, fitem) => (  
                        <div className="col-lg-3 col-md-6  " key={fitem}>
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <img src={featres.fIcon} alt=""/>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2><Link onClick={ClickHandler} to={featres.link}>{featres.title}</Link></h2>
                                    <p>{featres.des}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Features;