import React from 'react'
import {Link} from 'react-router-dom'
import abimg from '../../images/about.jpg'
import spimg1 from '../../images/ab-shape-1.png'
import spimg2 from '../../images/ab-shape-2.png'
import spicon from '../../images/icon/badge.svg'
import sign from '../../images/signeture.png'
import about from '../../images/aelyx/abt.jpg'


const About = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <section id="about" name="about" className={`wpo-about-section ${props.abClass}`}
        style={{
            width: '100%', // Full width of the container
            height: '60%', // Full viewport height (100% of the screen height)
            display: 'flex', // Flex container to align children (optional based on content)
            flexDirection: 'column', // Align content in a column (optional)
            justifyContent: 'center', // Center content vertically (optional)
            alignItems: 'center', // Center content horizontally (optional)
            padding:0
            
          }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-10 col-12">
                        <div className="wpo-about-wrap">
                        <div className="wpo-about-img">
                            <img src={about} alt=""/>
                            <div className="wpo-ab-shape-1"><img src={spimg1} alt=""/></div>
                            <div className="wpo-ab-shape-2"><img src={spimg2} alt=""/></div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                        
                        <div className="wpo-about-icon-content">
                            <h2>About Us </h2>
                            <p>We are a cutting-edge platform designed to empower Registered Investment Advisors (RIAs) in India by streamlining every aspect of client management, from onboarding to order execution. Our mission is to bridge the gap between technology and personalized financial advisory services, ensuring that every client receives top-tier solutions with ease and transparency. Backed by a team of financial experts and technologists, we aim to provide a secure and efficient digital environment that enhances client experiences and advisor efficiency.</p>
                            
                            
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;