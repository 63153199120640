import React, { Component } from 'react'
import Logo from '../../images/logo.png'
import {Link}  from 'react-router-dom'
import MobileMenu from '../../components/MobileMenu'
import HeaderTopbar from '../../components/HeaderTopbar'
import Logo1 from '../../images/aelyx/ay.png'


export default class Header2 extends Component {

    state = {
        isSearchShow: false,
    }

    searchHandler = () => {
        this.setState({
            isSearchShow: !this.state.isSearchShow
        })
    }

    
    
    render(){
        const {isSearchShow} = this.state;

        const SubmitHandler = (e) =>{
            e.preventDefault()
         }
         const ClickHandler = () =>{
            window.scrollTo(10, 0);
         }
       return(	
        <header id="header" className="wpo-site-header">
            <nav className="navigation navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                            <div className="mobail-menu">
                                <MobileMenu/>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6">
                            <div className="navbar-header">
                                <Link onClick={ClickHandler} className="navbar-brand" to="/"><img src={Logo1}
                                        alt="" height="50px" width="55px"/></Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-1 col-1">
                            <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                <button className="menu-close"><i className="ti-close"></i></button>
                                <ul className="nav navbar-nav mb-2 mb-lg-0">
                                 <li><Link onClick={ClickHandler} to="/home">Home</Link></li>

                                    
                                    <li><Link onClick={ClickHandler} to="/home">About</Link></li>

                                    

                                    <li><Link onClick={ClickHandler} to="/home">Service</Link></li>

                                      
                                    <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>

                                </ul>
        
                            </div>
                        </div>
                        
                    </div>
                </div>
            </nav>
        </header>
       )
   }
}
