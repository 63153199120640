import React from 'react'
import ServiceSingleSidebar from './service-single-components/sidebar'
import Solutions from './service-single-components/solution'
import Benefits from './service-single-components/benefits'
import ssimg from '../../images/service-single/1.jpg'
import ssimg2 from '../../images/service-single/2.jpg'
import ssimg3 from '../../images/service-single/3.jpg'


const ServiceSingleSection = (props) => {
    return(
        <section className="wpo-service-single-section ">
            <div className="container flex justify-center">
                <div className="">
                    <div className="col-lg-12 col-md-12">
                        <div className="wpo-service-single-wrap">
                            <div className="wpo-service-single-content">
                            <div className="container mx-auto my-10 p-5">
            <div className="flex justify-center"><h1 className="text-3xl font-bold mb-4 ">Privacy Policy</h1></div>
            <p className="mb-4">
                This Privacy Policy shall govern the website and any other such applications provided by Aelyx (Aelyx Techventures) (hereinafter referred to as the "Platform").
                Aelyx Techventures (“Aelyx”) is concerned about the privacy of its Data Subjects (also referred to as "you", "your", "yourself"), which includes all persons using the website of Aelyx Techventures ("Website").
            </p>

            <p className="mb-4">
                <ul>
                <li>(i) Those persons registering with Aelyx Techventures personally or through authorized referral partners of Aelyx, and </li>
                <li>(ii) persons authorized to register with Aelyx Techventures on behalf of corporate applicants.</li>
                </ul>
            </p>

            <p className="mb-4">
            Aelyx (Aelyx Techventures) has accordingly provided this Privacy Policy (“Privacy Policy” or "Policy") to familiarise you with the manner in which Aelyx (Aelyx Techventures) collects, uses and discloses your information collected through the Platform. Aelyx (Aelyx Techventures) reserves the right to change, modify, add or remove portions of this Privacy Policy at any time. Any updates to the privacy policy become effective when posted on the Platform. If we make any material changes, we notify you by email or by providing the revised privacy policy on https://www.aelyx.in/. Your continued use of our services following the update means that you accept Aelyx’s (Aelyx Techventures) updated privacy policy and you shall be bound by the modified Privacy Policy as on the date and time of browsing. You are therefore advised to periodically visit this page to review the current Privacy Policy to which you are bound. All terms not defined in this document shall have the meanings ascribed to them in the Terms of Use or any other such agreement entered into between Aelyx (Aelyx Techventures) and you. If you have any questions or complaints regarding our privacy policy or practices or if you need assistance in exercising any of your choices under this policy, please reach out to us at support@aelyx.in.
Please note in order to use our services one has to be 21 years old or above

            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">Collection of Information</h2>
            <p className="mb-4">
            Your privacy is important to us and we try to ensure we collect minimum required information in order to provide you with our services to protect your account.
The collected information which includes your name, address, phone number and email address may be collected at the time of your registration on the Platform.
We record and retain details of your activities on the Platform which is a platform permitting prospective borrowers and lenders to enter into transactions with one another through Aelyx (Aelyx Techventures) as well as carrying out risk assessment and support services.
We record and retain records of your browsing activities on our platform including but not limited to IP addresses and browsing patterns. In addition, we gather statistical information about the Platform and visitors to the Platform including, but not limited to IP addresses, browser software, operating system, software and hardware attributes, pages viewed, number of sessions and unique visitors.
It is mandatory for you to provide certain categories of information. In the event that you do not provide any or sufficient information marked as mandatory, we may not be able to complete the registration process or provide you with our products or Services.
The synopsis of information collected from your side are included but not limited to:
<ol>
    <li>Your identifying information and application information (eg. Name, address, email, telephone, date of birth, identity number, employment information etc.)
    </li>
    <li>We collect information on your computer and visits to the platform including your IP address, geographical location, browser type, referral source, length of visit, button clicks, page views etc.
    </li>
    <li>We receive information from the cookies and web beacons imposed by us and our partners on our platform.
    </li>
    </ol>
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">Use Of Your Information</h2>
            <p className="mb-4">
            We use your personal information to provide the services you request. To the extent we use your personal information to market to you, we will provide you the ability to opt-out of such uses. We use your personal information to resolve disputes; troubleshoot problems; help to promote a safe service; collect money; measure consumer interest in our services, inform you about offers, services, and updates; customize your experience; detect and protect us against error, fraud and other criminal activity; enforce any specific terms and other conditions governing your relationship with Aelyx (Aelyx Techventures) and as otherwise described to you at the time of collection.

In our efforts to continually improve our service offerings, we collect and analyse demographic and profile data about your activity on our platform. We use this information to improve our services and credit model. We also use this information to improve our website’s usability and to evaluate the success of marketing campaigns and other activities.
We may also use your personal information to select you for certain marketing offers, newsletters, surveys and/or requests for feedback regarding your experience. We may use your personal information both to select you for an offer and to communicate that offer to you.
We use cookies and beacons, tags and scripts to analyse trends, administer the website and track the user movement around the website, including gathering demographic information about our user base. We may use cookies when you sign in to your account to keep track of your personal sessions, authenticate your account and detect fraud. Cookies are also used together statistical data, such as pages frequently visited, what is downloaded and address of sites visited immediately after coming to our site. You can use or deny use of the cookies but that would limit your ability to use some features of our website.

            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">Sharing of Personal Information</h2>
            <p className="mb-4">
            We may share personal information with other corporate entities and affiliates to help detect and prevent identity theft, fraud and other potentially illegal acts; correlate related or multiple accounts to prevent misuse of our services.
We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to court orders, government agencies having authority to require such disclosure or any other legal process. We may disclose personal information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce any specific terms governing your relationship with Aelyx (Aelyx Techventures) or this Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party or protect the rights, property or personal safety of our Data Subjects or the general public.
We and our affiliates may share some or all of your personal information with another business entity we (or our assets) plan to merge with, or be acquired by that business entity, or re-organisation, amalgamation, restructuring of business. If such transaction occurs then that other business entity (or the new combined entity) will be required to follow this Privacy Policy with respect to your personal information.
We will share your information with third parties as permitted by law, including in the following specific ways:

            </p>
<ol>
    <li>Designated Bank. All loans originated through us are made through a third party entity. If you request a loan through the Platform, you are also providing your information to the designated bank and further information may be provided to the designated bank after your loan originates. The use of your information by the designated bank is governed by their privacy policy.
    </li>
    <li>Third Party Service Providers. We rely on third party service providers to help us conduct our business and marketing activities, such as maintaining our files and records, offering customer service, sending marketing communications or facilitating fund transfers. We may share your information with such companies, which are authorized to use your personal information only as necessary to provide these services to us. Information may also be shared among affiliates for this purpose where one affiliated company provides services to or on behalf of another affiliated company in connection with your account.
    </li>
    <li>Affiliates. Our affiliates, including entities covered by this policy and other companies related by common ownership or control may share your information with each other, including information related to your credit worthiness, for purposes of general business analysis or to present targeted marketing offers to you or others.
    </li>
    <li>Legal Requests & Regulatory Requirements. We may also share information to comply with, or to allow investors or potential investors to comply with, any applicable law, regulation, legal process or governmental request; or for the purposes of limiting fraud; or in connection with an audit or the sale of Aelyx (Aelyx Techventures) to a third party.
    </li>
    <li>Sharing of Anonymized Data. We may share aggregated and anonymized data sets including your information with third party business partners, service providers, and among Aelyx (Aelyx Techventures) affiliates. No personally identifiable information will be included in these data sets.
    </li>
</ol>

            <h2 className="text-2xl font-semibold mt-6 mb-3">Changing Your Personal Information</h2>
            <p className="mb-4">
                You can access the personal information you have provided to us by logging into your account. We will retain your information for a minimum of seven years or as long as needed to provide you services.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">General</h2>
            <p className="mb-4">
            By using the Platform and/ or by providing our information, you consent to the collection and use of the information you disclose on the Platform in accordance with this Privacy Policy, including but not limited to your consent for sharing your information as per this Privacy Policy.

            </p>
            <ol>
                <li>(i) In the event that any of the provisions in this Privacy Policy contravene the Information Technology Act 2000 and rules made there under, then the Information Technology Act 2000 supersedes any of those contravening provisions.
                </li>
                <li>(ii) This Privacy Policy shall be interpreted in accordance with the Laws of India.
               As per the provisions of the Information Technology Act 2000 and Information Technology Rules 2000, the name and contact details of the Grievance Officer are provided below:
                </li>
            </ol>

            <p className="mb-1">
               
                <br />
                Address: 6-T-92/5(1), Second Floor, MELRIC, Old Post Office Road, Iddya Village, Surathkal, Mangalore, Karnataka - 575014.<br/>

                Email: support@aelyx.in <br />
                Phone: +91-9513716226
            </p>
        </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceSingleSection;