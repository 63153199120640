import React from 'react'
import {Link} from 'react-router-dom'

import srvimg1 from '../../images/icon/clipboard.svg'
import srvimg2 from '../../images/icon/briefcase.svg'
import srvimg3 from '../../images/icon/chart.svg'
import srvimg4 from '../../images/icon/badge.svg'
import srvimg5 from '../../images/icon/goal.svg'
import srvimg6 from '../../images/icon/handshake.svg'


const Services = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


     const service = [
         {
            sIcon:srvimg1,
            title:'Client Onboarding:',
            des:'Effortlessly onboard clients with a secure, user-friendly process that covers identity verification, risk profiling, and selection of tailored advisory services to match their financial needs.',      
            link:'/',
         },
         {
            sIcon:srvimg2,
            title:'Service Selection & Agreement Signing:',
            des:'Clients can choose from a range of investment services, followed by a seamless digital agreement signing process, ensuring transparency and compliance at every step.',      
            link:'/',
         },
         {
            sIcon:srvimg3,
            title:'Payment Gateway Integration:',
            des:'Complete secure online transactions through multiple payment methods, including direct bank transfers and digital wallets, offering clients peace of mind and convenience.',      
            link:'/',
         },
         {
            sIcon:srvimg4,
            title:'Order Execution',
            des:'Execute orders directly on India’s leading exchanges with real-time tracking, ensuring fast and compliant execution of buy and sell orders as per client directives.',      
            link:'/',
         },
         {
            sIcon:srvimg5,
            title:'Investment Advisory & Portfolio Management:',
            des:'Receive personalized investment advice and comprehensive portfolio management tailored to individual risk profiles and financial goals, leveraging market insights and expertise.',      
            link:'/',
         },
         
         
     ]


    return(
        <section id="services" className="wpo-service-section section-padding"
        
        >
            <div className="container">
                <div className="row" >
                    <div className="col-12">
                        <div className="wpo-section-title">
                          
                            <h2> Our Services</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {service.map((service, sitem) => (
                        <div className="col-lg-4 col-md-6 col-12 " key={sitem}>
                            <div className="wpo-service-item">
                                <div className="wpo-service-icon">
                                    <div className="icon">
                                        <img src={service.sIcon} alt=""/>
                                    </div>
                                </div>
                                <div className="wpo-service-text">
                                    <h2><Link onClick={ClickHandler} to={service.link}>{service.title}</Link></h2>
                                    <p>{service.des}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Services;