import React from 'react'
import {Link}  from 'react-router-dom'
import Logo from '../../images/aelyx/ay.png'

const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

  return(
    <footer id="footer" className=" wpo-site-footer">
        <div className="flex item-center wpo-upper-footer  ">
            <div className="container mx-auto">
                <div className="row flex " style={{justifyContent:"space-evenly"}}>

                    {/* logo  */}
                    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget about-widget">
                            <div className="logo widget-title ">
                                <Link onClick={ClickHandler} to="/">
                                    <img src={Logo} alt="blog" height="50px" width="55px"/>
                                </Link>
                               
                            </div>
                            <p>"Streamlining investment advisory services with secure, compliant, and client-centric solutions for the modern financial landscape."
                            </p>
                            
                        </div>
                    </div>
                    {/* contact */}
                    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget wpo-service-link-widget">
                            <div className="widget-title">
                                <h3>Contact </h3>
                            </div>
                            <div className="contact-ft">
                                <ul>
                                    <li><i className="fi flaticon-location"></i> 6-T-92/5(1), Second Floor, MELRIC, Old Post Office Road, Iddya Village, Surathkal, Mangalore, Karnataka - 575014.</li>
                                    <li><i className="fi flaticon-phone-call"></i>+91-9513716226.</li>
                                    <li><i className="fi flaticon-send"></i>support@aelyx.in </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* service */}
                    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Services </h3>
                            </div>
                            <ul>
                                <li><Link onClick={ClickHandler} to="/service-single">Privacy Policy</Link></li>
                                <li><Link onClick={ClickHandler} to="/terms-condition">Terms & Conditions</Link></li>
                                <li><Link onClick={ClickHandler} to="/service-single">Return & Refund</Link></li>
                                
                            </ul>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
        {/* copyright */}
        <div className="wpo-lower-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <p className="copyright"> Copyright &copy; 2024 by <Link onClick={ClickHandler} to="/home">Aelyx Techventures</Link>. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;